import React from "react";
import classNames from "../../functions/classNames";
import "./Spinner.css";

/**
 * Spinning loader
 * @param {Object} $
 * @param {String} $.className - additional className
 * @param {String} $.size - size type
 * @param {String} $.theme - spinner theme
 * @param {Boolean} $.withLabel - inner prop when is child of `Spinner`
 */
export const SpinnerCircle = ({ className, withLabel, size, theme }) => (
  <div
    className={classNames(
      "Spinner",
      withLabel && "Spinner--with-label",
      size && `Spinner--size-${size}`,
      theme && `Spinner--${theme}`,
      className,
    )}
  >
    <span />
  </div>
);

/**
 * Spinning loader with text nearby
 * @param {Object} $
 * @param {String} $.size - size type
 * @param {String} $.theme - `SpinnerCircle` theme
 * @param {Array[React::Element]} $.children - will be added into `Spinner__label`
 */
export default ({ children, theme, size }) => {
  if (children) {
    return (
      <div className="Spinner__group">
        <SpinnerCircle withLabel theme={theme} size={size} />
        <span className="Spinner__label">{children}</span>
      </div>
    );
  }
  return <SpinnerCircle theme={theme} size={size} />;
};

// TODO: REDESIGN
export const SpinnerOverlay = () => (
  <div className="SpinnerOverlay">
    <SpinnerCircle size="large" />
  </div>
);
