import React from "react";
import * as Sentry from "@sentry/browser";
import WebFont from "webfontloader";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ensureReady, getSerializedData } from "@jaredpalmer/after";
import { Provider } from "react-redux";
import { loadableReady } from "@loadable/component";
import routes from "./routes";
import configureStore from "./stores/configureStore";
import { setFontsLoaded } from "./functions/fonts";
import { saveUrlParamsToCookies } from "./functions/urlParamsToCookiesUtils";
import preventFocusOnClick from "./functions/preventFocusOnClick";
import { identify, pageViewed } from "./functions/analytics";
import "normalize.css";
import "flexboxgrid2/flexboxgrid2.css";
import "./common/typo.css";
import "./client.css";
import { disableDiscountPromo } from "./functions/promo/discountPromo";
import AfterWrapper from "./components/AfterWrapper";

const runtimeConfig = getSerializedData("env");
window.env = runtimeConfig;

const preloadedState = getSerializedData("preloaded_state");
const store = configureStore(preloadedState);
const userStore = store.getState().user;
pageViewed();

if (userStore.isLogged && userStore.isFetched) {
  identify(userStore.user);
}

/**
 * Hide discount promo banner if user is Logged
 */
if (userStore.isLogged && userStore.isFetched) {
  disableDiscountPromo();
}

Sentry.init({
  enabled: ["production", "development", "staging"].includes(runtimeConfig.ENV),
  dsn: runtimeConfig.RAZZLE_SENTRY_DSN,
  environment: runtimeConfig.ENV,
  integrations: [Sentry.browserTracingIntegration()],

  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "http://localhost:3000",
    /^https:\/\/wegotrip\.com\/.*/,
    /^https:\/\/wegotrip\.ru\/.*/,
    /^https:\/\/wegotrip\.site\/.*/,
  ],
});

// TODO: error boundary
Sentry.getCurrentScope().setExtra("side", "client");

/**
 * Tracking font loading:
 * At first load:
 * - loads fonts in parallel with `<link>` to CSS in `Document` to detect end of loading
 * - sets cookie that font was cached
 * At other loads:
 * - on the server side in `Document` puts into HTML class with cached font
 * - takes from cache CSS from `<link>` in `Document` with all fonts on client
 * - takes from cache fonts or load them if cache was cleared but cookie was not
 */
WebFont.load({
  typekit: { id: "fmr8fdm" },
  active: () => setFontsLoaded(),
});

saveUrlParamsToCookies();

function renderApp() {
  const renderMethod = module.hot ? render : hydrate;

  const hideLoader = () => {
    if (document.readyState === "complete") {
      const loader = document.getElementById("initial-loader");

      if (loader) {
        loader.classList.add("hidden");

        setTimeout(() => {
          loader.remove();
        }, 300);
      }
    } else {
      window.addEventListener("load", hideLoader);
    }
  };

  ensureReady(routes).then(data => {
    loadableReady(() => {
      renderMethod(
        <BrowserRouter>
          <Provider store={store}>
            <AfterWrapper data={data} routes={routes} store={store} transitionBehavior="instant" />
          </Provider>
        </BrowserRouter>,
        document.getElementById("root"),
        hideLoader,
      );
    });
  });
}

renderApp();
preventFocusOnClick();

if (module.hot) {
  module.hot.accept("./routes", renderApp);
}
