/* eslint-disable import/no-cycle */
/* eslint-disable no-prototype-builtins */
/* eslint-disable global-require */
import React from "react";
import useCurrentLanguage from "../../../functions/languages/useCurrentLanguage";
import sendEvent from "../../../functions/analytics";
import Image from "../../Image";
import "./Apps.css";

/**
 * Importing icons and images
 */
const BADGES = {
  "app-store": {
    ru: require("../assets/app-store-badge-ru.svg"),
    en: require("../assets/app-store-badge-en.svg"),
  },
  "google-play": {
    ru: require("../assets/google-play-badge-ru.svg"),
    en: require("../assets/google-play-badge-en.svg"),
  },
};

/**
 * Setting links
 */
const LINKS = {
  "app-store": {
    ru: "https://wgtr.app/ru/ios",
    en: "https://wgtr.app/ios",
  },
};

function getLocalContent(dictionary, type, lang) {
  if (!dictionary.hasOwnProperty(type)) return "";
  if (!dictionary[type].hasOwnProperty(lang)) {
    // eslint-disable-next-line no-param-reassign
    lang = "en";
  }
  return dictionary[type][lang];
}

/**
 * Sends "Appstore Clicked" event to analytics systems
 */
function sendMobileAppMarketEnteredEvent() {
  sendEvent("track", "Appstore Clicked", { url: window.location.href });
}

/**
 * Shows download buttons for Google Play and App Store
 */
export default function FooterApps() {
  const lang = useCurrentLanguage();
  return (
    <div className="Apps">
      <a
        href={getLocalContent(LINKS, "app-store", lang)}
        target="_blank"
        rel="noopener"
        className="Apps__item"
        aria-label={lang === "ru" ? "Скачать в App Store" : "Download on App Store"}
        onClick={() => sendMobileAppMarketEnteredEvent()}
      >
        <Image
          staticImage
          src={getLocalContent(BADGES, "app-store", lang)}
          loading="lazy"
          alt={lang === "ru" ? "Значок App Store" : "App Store badge"}
        />
      </a>
      <a
        href="https://wgtr.app/android"
        target="_blank"
        rel="noopener"
        className="Apps__item"
        aria-label={lang === "ru" ? "Скачать в Google Play" : "Get it on Google Play"}
        onClick={() => sendMobileAppMarketEnteredEvent()}
      >
        <Image
          staticImage
          src={getLocalContent(BADGES, "google-play", lang)}
          loading="lazy"
          alt={lang === "ru" ? "Значок Google Play" : "Google Play badge"}
        />
      </a>
    </div>
  );
}
