import { useEffect, useState } from "react";
import { getValue } from "firebase/remote-config";
import { remoteConfig, fetchAndActivate } from "../../firebase";
import useCurrentLanguage from "./languages/useCurrentLanguage";

const useRemoteConfig = () => {
  const [config, setConfig] = useState({
    sellingTools: false,
    partnerSerachCheckboxEnabled: false,
    partnerSearchCheckboxVisible: true,
    showPopupInStore: false,
  });

  const lang = useCurrentLanguage();

  useEffect(() => {
    const cachedConfig =
      typeof window !== "undefined" ? localStorage.getItem("remoteConfig") : null;

    if (cachedConfig) {
      setConfig(JSON.parse(cachedConfig));
    }

    fetchAndActivate(remoteConfig)
      .then(() => {
        const sellingTools = getValue(remoteConfig, "selling_tools").asBoolean();
        const partnerSerachCheckboxEnabled = getValue(
          remoteConfig,
          "partner_search_checkbox_enabled",
        ).asBoolean();
        const partnerSearchLabel = getValue(
          remoteConfig,
          `partner_search_label_${lang}`,
        ).asString();
        const partnerSearchCheckboxVisible = getValue(
          remoteConfig,
          "partner_search_checkbox_visible",
        ).asBoolean();
        const showPopupInStore = getValue(remoteConfig, "show_popup_in_store").asBoolean();

        const newConfig = {
          sellingTools,
          partnerSerachCheckboxEnabled,
          partnerSearchLabel,
          partnerSearchCheckboxVisible,
          showPopupInStore,
        };

        setConfig(newConfig);

        if (typeof window !== "undefined") {
          localStorage.setItem("remoteConfig", JSON.stringify(newConfig));
        }
      })
      .catch(error => console.error("Error:", error));
  }, []);

  return config;
};

export default useRemoteConfig;
