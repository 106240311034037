import { Api } from "./fetchFromApi";
import toQueryString from "./toQueryString";

export function getFwdHeaders(request) {
  try {
    if (!request) {
      return {};
    }
    const ip = request.headers["x-forwarded-for"] || request.connection.remoteAddress;
    return {
      "X-Forwarded-For": ip,
      "X-Real-IP": ip,
      REMOTE_ADDR: ip,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error while getFwdHeaders", { err });
    return {};
  }
}

/**
 * Fetching country by ip
 * Async function
 * @param {String} ip - client ip from `getInitialProps::req::ip`
 */
export async function fetchCountryByIp(request) {
  const headers = getFwdHeaders(request);

  try {
    const response = await fetch("https://get.geojs.io/v1/ip/geo.json", { headers });
    if (!response.ok) {
      throw new Error(`Failed to fetch geo data: ${response.status}`);
    }
    const geoData = await response.json();

    if (geoData && geoData.country_code) {
      return { data: { code: geoData.country_code } };
    }
  } catch (err) {
    console.error("Error fetching country from geojs.io", err);
  }

  try {
    const response = await fetch(`/api/countries/ip/`, { headers });
    if (!response.ok) {
      throw new Error(`Failed to fetch from internal API: ${response.status}`);
    }
    return await response.json();
  } catch (err) {
    console.error("Error while fetching country from our API", err);
    return {};
  }
}

/**
 * Fetching overview
 * Async function
 * @param {String} lang - code of the language used in current page
 * @param {String} ip - client ip from `getInitialProps::req::ip`
 */
// eslint-disable-next-line camelcase
export async function fetchOverview({ lang, expand_overview }, request) {
  try {
    return (
      await Api.get(
        `/api/v2/overview/?${toQueryString({
          lang,
          expand_overview,
          preorder: true,
        })}`,
        {
          headers: getFwdHeaders(request),
          lang,
        },
      )
    ).data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error while fetching overview", err);
    return {};
  }
}

/**
 * Fetching overview
 * Async function
 * @param {String} lang - code of the language used in current page
 * @param {String} ip - client ip from `getInitialProps::req::ip`
 */
export async function fetchCity(lang, cityId) {
  try {
    const cityPath = cityId ? `/api/v2/cities/${cityId}/` : `/api/v2/cities/`;
    return (
      await Api.get(`${cityPath}?${toQueryString({ lang, preorder: true })}`, {
        headers: { lang },
      })
    ).data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error while fetching city", err);
    return {};
  }
}

/**
 * Fetching available days for booking tour
 * @param {Number} id - product id
 * @param {String} start - start date in format `YYYY-MM-DD`
 * @param {String} end - end date in format `YYYY-MM-DD`
 * @returns {Object} - with `data` field if successful, with `error` if not
 */
export async function fetchDays(productId, start, end, categories = []) {
  try {
    const responce = await Api.post(`/api/v2/products/${productId}/days/`, {
      payload: { start, end, ...(categories.length ? { categories } : {}) },
    });

    if (!responce.data) {
      throw new Error();
    }

    return responce;
  } catch (error) {
    return { error };
  }
}

/**
 * Fetching available time for specified date fro booking tour
 * @param {Number} id - product id
 * @param {String} date - date in format `YYYY-MM-DD`
 * @returns {Object} - with `data` field if successful, with `error` if not
 */
export async function fetchTimeslots(productId, date) {
  try {
    const responce = await Api.post(`/api/v2/products/${productId}/timeslots/`, {
      payload: { date },
    });
    return responce;
  } catch (error) {
    return { error };
  }
}

/**
 * Fetching Linkings
 * Async function
 * @param {String} lang - code of the language used in current page
 * @param {String} id - city id`
 */
export async function fetchLinkings({ lang, id }) {
  if (lang === "ru") {
    return {};
  }

  try {
    return (await Api.get(`/api/v2/linkings/city/${id}/`, { lang })).data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error while fetching linkings ", err);
    return {};
  }
}
