/* eslint-disable import/no-mutable-exports */
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyB0cQI7yHfMscUc5Afg5woRYfkE45BjMhI",
  authDomain: "wegotrip-d91cc.firebaseapp.com",
  databaseURL: "https://wegotrip-d91cc.firebaseio.com",
  projectId: "wegotrip-d91cc",
  storageBucket: "wegotrip-d91cc.firebasestorage.app",
  messagingSenderId: "1061759672013",
  appId: "1:1061759672013:web:0ee9c129b6726880f89384",
  measurementId: "G-GKX770Z69W",
};

const app = initializeApp(firebaseConfig);

let analytics;
let remoteConfig;

if (typeof window !== "undefined") {
  analytics = getAnalytics(app);

  remoteConfig = getRemoteConfig(app);
  remoteConfig.settings.minimumFetchIntervalMillis = 1200000;
  remoteConfig.defaultConfig = {
    selling_tools: true,
    partner_search_checkbox_enabled: true,
    partner_search_label_en: "Open GetYourGuide in new tab",
    partner_search_label_ru: "Открыть Tripster в новой вкладке",
    partner_search_checkbox_visible: true,
    show_popup_in_store: true,
  };
}

export { app, analytics, remoteConfig, fetchAndActivate };
