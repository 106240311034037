import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CITIES,
  FETCH_CITIES_FAILURE,
  FETCH_CITIES_SUCCESS,
  FETCH_CITY,
  FETCH_CITY_FAILURE,
  FETCH_CITY_SUCCESS,
  FETCH_INTERESTS_FAILURE,
  FETCH_INTERESTS_SUCCESS,
  FETCH_TRAVELERS_INTERESTS,
  FETCH_TRAVELERS_INTERESTS_FAILURE,
  FETCH_TRAVELERS_INTERESTS_SUCCESS,
} from "../types";

export const init = {
  city: [],
  cities: [],
  loading: false,
  error: null,
  categories: [],
  travelersInterests: [],
  interests: [],
  loadingTravelersInterests: false,
};

export default function categories(state = init, action) {
  switch (action.type) {
    case FETCH_CITY:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        city: action.payload,
      };

    case FETCH_CITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_CITIES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        cities: action.payload,
      };

    case FETCH_CITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case FETCH_CATEGORIES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };

    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case FETCH_TRAVELERS_INTERESTS:
      return {
        ...state,
        loadingTravelersInterests: true,
        error: null,
      };

    case FETCH_TRAVELERS_INTERESTS_SUCCESS:
      return {
        ...state,
        loadingTravelersInterests: false,
        travelersInterests: action.payload,
      };

    case FETCH_TRAVELERS_INTERESTS_FAILURE:
      return {
        ...state,
        loadingTravelersInterests: false,
        error: action.error,
      };

    case FETCH_INTERESTS_SUCCESS:
      return {
        ...state,
        interests: action.payload,
      };

    case FETCH_INTERESTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
