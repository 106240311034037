export const location = {
  location: {
    shape:
      '<g> <path d="M7.625 15.75L7.20897 16.374C7.4609 16.542 7.7891 16.542 8.04103 16.374L7.625 15.75ZM7.625 15.75C8.04103 16.374 8.04121 16.3739 8.04142 16.3738L8.04194 16.3734L8.04337 16.3725L8.04776 16.3695L8.06253 16.3595C8.07501 16.3511 8.09268 16.339 8.1152 16.3234C8.16023 16.2922 8.22469 16.2469 8.30582 16.1883C8.46799 16.0712 8.69718 15.9005 8.9711 15.6824C9.51775 15.2471 10.2486 14.6181 10.982 13.8439C12.4181 12.3281 14 10.114 14 7.625C14 5.93424 13.3284 4.31274 12.1328 3.11719C10.9373 1.92165 9.31576 1.25 7.625 1.25C5.93424 1.25 4.31274 1.92165 3.11719 3.11719C1.92165 4.31274 1.25 5.93424 1.25 7.625C1.25 10.114 2.83195 12.3281 4.26804 13.8439C5.00142 14.6181 5.73225 15.2471 6.2789 15.6824C6.55282 15.9005 6.78201 16.0712 6.94418 16.1883C7.02531 16.2469 7.08977 16.2922 7.1348 16.3234C7.15732 16.339 7.17499 16.3511 7.18747 16.3595L7.20224 16.3695L7.20663 16.3725L7.20806 16.3734L7.20858 16.3738C7.20879 16.3739 7.20897 16.374 7.625 15.75Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M7.5 10C8.88071 10 10 8.88071 10 7.5C10 6.11929 8.88071 5 7.5 5C6.11929 5 5 6.11929 5 7.5C5 8.88071 6.11929 10 7.5 10Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </g> ',
  },
  "location/white": {
    shape:
      '<path fill="#000" fill-opacity=".98" d="M12 11.5c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0 0 12 8.5c-.417 0-.77.146-1.062.438A1.444 1.444 0 0 0 10.5 10c0 .417.146.77.438 1.062.291.292.645.438 1.062.438Zm0 8.75c2.083-1.817 3.687-3.633 4.812-5.45 1.125-1.817 1.688-3.35 1.688-4.6 0-1.967-.629-3.575-1.887-4.825C15.354 4.125 13.817 3.5 12 3.5c-1.817 0-3.354.625-4.612 1.875C6.129 6.625 5.5 8.233 5.5 10.2c0 1.25.563 2.783 1.688 4.6 1.125 1.817 2.729 3.633 4.812 5.45Zm0 1.375c-2.533-2.25-4.417-4.333-5.65-6.25C5.117 13.458 4.5 11.733 4.5 10.2c0-2.35.754-4.22 2.263-5.612C8.271 3.196 10.017 2.5 12 2.5s3.73.696 5.238 2.088C18.746 5.979 19.5 7.85 19.5 10.2c0 1.533-.617 3.258-1.85 5.175-1.233 1.917-3.117 4-5.65 6.25Z"/>',
    viewBox: [0, 0, 24, 24],
  },
  "location/pin": {
    shape:
      '<path fill="#000" fill-opacity=".51" d="M12 14.5c.75 0 1.442-.17 2.075-.512a4.46 4.46 0 0 0 1.55-1.363 6.038 6.038 0 0 0-1.687-.838A6.427 6.427 0 0 0 12 11.5c-.683 0-1.33.096-1.938.287a6.038 6.038 0 0 0-1.687.838 4.46 4.46 0 0 0 1.55 1.363A4.304 4.304 0 0 0 12 14.5Zm0-5c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062 0-.417-.146-.77-.438-1.062A1.444 1.444 0 0 0 12 6.5c-.417 0-.77.146-1.062.438A1.444 1.444 0 0 0 10.5 8c0 .417.146.77.438 1.062.291.292.645.438 1.062.438Zm0 10.75c2.083-1.817 3.687-3.633 4.812-5.45 1.125-1.817 1.688-3.35 1.688-4.6 0-1.967-.629-3.575-1.887-4.825C15.354 4.125 13.817 3.5 12 3.5c-1.817 0-3.354.625-4.612 1.875C6.129 6.625 5.5 8.233 5.5 10.2c0 1.25.563 2.783 1.688 4.6 1.125 1.817 2.729 3.633 4.812 5.45Zm0 1.375c-2.533-2.25-4.417-4.333-5.65-6.25C5.117 13.458 4.5 11.733 4.5 10.2c0-2.35.754-4.22 2.263-5.612C8.271 3.196 10.017 2.5 12 2.5s3.73.696 5.238 2.088C18.746 5.979 19.5 7.85 19.5 10.2c0 1.533-.617 3.258-1.85 5.175-1.233 1.917-3.117 4-5.65 6.25Z"/>',
    viewBox: [0, 0, 24, 24],
  },
};
