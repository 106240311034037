import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerOverlay } from "../Spinner";
import * as types from "../../stores/types";

const PageLoadingProvider = ({ children }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.loading.isPageLoading);
  const location = useLocation();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    let mounted = true;

    const handleRouteChange = () => {
      if (mounted) {
        dispatch({ type: types.SET_PAGE_LOADING });
      }
    };

    const handleLoadComplete = () => {
      if (mounted) {
        setTimeout(() => {
          dispatch({ type: types.CLEAR_PAGE_LOADING });
        }, 300);
      }
    };

    handleRouteChange();

    const checkLoading = () => {
      if (document.readyState === "complete") {
        handleLoadComplete();
      } else {
        window.requestIdleCallback(checkLoading);
      }
    };

    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(checkLoading);
    } else {
      window.setTimeout(checkLoading, 100);
    }

    return () => {
      mounted = false;
    };
  }, [location.pathname, dispatch]);

  if (typeof window === "undefined") {
    return children;
  }

  return (
    <>
      {isLoading && !isInitialMount.current && <SpinnerOverlay />}
      {children}
    </>
  );
};

export default PageLoadingProvider;
