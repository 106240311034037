import React from "react";
import { PAYMENT_GATES } from "../../../functions/payments";
import classNames from "../../../functions/classNames";
import useCurrentLanguage from "../../../functions/languages/useCurrentLanguage";
import "./PaymentGatesItem.css";

import svgGooglePay from "./assets/google-pay.svg";
import svgApplePay from "./assets/apple-pay.svg";
import svgMir from "./assets/mir.svg";
import svgMasterCard from "./assets/mastercard.svg";
import svgVisa from "./assets/visa.svg";
import svgAmericanExpress from "./assets/american-express.svg";
import svgMaestro from "./assets/maestro.svg";
import svgUnionPay from "./assets/unionpay.svg";
import svgCarteBancaire from "./assets/carte-bancaire.svg";

/**
 * Локализованные названия платежных систем
 * @type {Object}
 */
const PAYMENT_SYSTEM_NAMES = {
  [PAYMENT_GATES.ApplePay]: {
    ru: "Оплата через Apple Pay",
    en: "Pay with Apple Pay",
  },
  [PAYMENT_GATES.GooglePay]: {
    ru: "Оплата через Google Pay",
    en: "Pay with Google Pay",
  },
  [PAYMENT_GATES.Mir]: {
    ru: "Оплата картой МИР",
    en: "Pay with MIR card",
  },
  [PAYMENT_GATES.MasterCard]: {
    ru: "Оплата картой Mastercard",
    en: "Pay with Mastercard",
  },
  [PAYMENT_GATES.Visa]: {
    ru: "Оплата картой Visa",
    en: "Pay with Visa",
  },
  [PAYMENT_GATES.AmericanExpress]: {
    ru: "Оплата картой American Express",
    en: "Pay with American Express",
  },
  [PAYMENT_GATES.Maestro]: {
    ru: "Оплата картой Maestro",
    en: "Pay with Maestro",
  },
  [PAYMENT_GATES.UnionPay]: {
    ru: "Оплата картой UnionPay",
    en: "Pay with UnionPay",
  },
  [PAYMENT_GATES.CartesBancaires]: {
    ru: "Оплата картой Cartes Bancaires",
    en: "Pay with Cartes Bancaires",
  },
};

/**
 * Payment gate icons
 * @type {Object}
 */
const GATE_ICONS = {
  [PAYMENT_GATES.ApplePay]: { src: svgApplePay, h: 16, m: 3 },
  [PAYMENT_GATES.GooglePay]: { src: svgGooglePay, h: 14, m: 3 },
  [PAYMENT_GATES.Mir]: { src: svgMir, h: 10 },
  [PAYMENT_GATES.MasterCard]: { src: svgMasterCard, h: 21 },
  [PAYMENT_GATES.Visa]: { src: svgVisa, h: 12 },
  [PAYMENT_GATES.AmericanExpress]: { src: svgAmericanExpress, h: 40 },
  [PAYMENT_GATES.Maestro]: { src: svgMaestro, h: 21 },
  [PAYMENT_GATES.UnionPay]: { src: svgUnionPay, h: 28 },
  [PAYMENT_GATES.CartesBancaires]: { src: svgCarteBancaire, h: 20 },
};

/**
 * Payment gate icon
 * @param {Object} $
 * @param {String} $.className - additional CSS class name
 * @param {String?} $.theme - component theme (`"white"`)
 * @param {*} $.gate - id of the gate (from `PAYMENT_GATES`)
 */
export default function PaymentGatesItem({ className, gate, theme }) {
  const lang = useCurrentLanguage();
  const icon = GATE_ICONS[gate];
  const paymentSystemName = PAYMENT_SYSTEM_NAMES[gate]?.[lang === "ru" ? "ru" : "en"];

  return !icon ? null : (
    <span
      className={classNames("PaymentGatesItem", theme && `PaymentGatesItem--${theme}`, className)}
    >
      <img
        src={icon.src}
        height={icon.h}
        alt={paymentSystemName || ""}
        {...(icon.m ? { style: { marginTop: `${icon.m}px` } } : {})}
      />
    </span>
  );
}
