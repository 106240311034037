import * as types from "../types";

const initialState = {
  isPageLoading: false,
};

export default function loading(state = initialState, action) {
  switch (action.type) {
    case types.SET_PAGE_LOADING:
      return {
        ...state,
        isPageLoading: true,
      };
    case types.CLEAR_PAGE_LOADING:
      return {
        ...state,
        isPageLoading: false,
      };
    default:
      return state;
  }
}
