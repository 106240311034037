import React, { useEffect } from "react";
import { After } from "@jaredpalmer/after";
import { useDispatch } from "react-redux";
import * as types from "../../stores/types";

const AfterWrapper = ({ routes, data, store, transitionBehavior }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleStart = () => {
      dispatch({ type: types.SET_PAGE_LOADING });
    };

    const handleComplete = () => {
      dispatch({ type: types.CLEAR_PAGE_LOADING });
    };

    window.addEventListener("beforeunload", handleStart);
    window.addEventListener("load", handleComplete);

    return () => {
      window.removeEventListener("beforeunload", handleStart);
      window.removeEventListener("load", handleComplete);
    };
  }, [dispatch]);

  return (
    <After data={data} routes={routes} store={store} transitionBehavior={transitionBehavior} />
  );
};

export default AfterWrapper;
