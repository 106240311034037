import React from "react";
import classNames from "../../functions/classNames";
import Link from "../Link";
import "./Button.css";

export default ({
  children,
  theme,
  width,
  icon,
  iconPosition = "after",
  bordered,
  className,
  hideTextOnMobile,
  hideTextOnTablet,
  inactive,
  to,
  Block = "button",
  size,
  "aria-label": ariaLabel,
  ...props
}) => {
  const ButtonTag = to ? Link : Block;

  const needsAriaLabel = icon && !children;
  const accessibilityProps = needsAriaLabel ? { "aria-label": ariaLabel } : {};

  // const handleClick = event => {
  //   event.stopPropagation();
  //   if (props.onClick) {
  //     props.onClick(event);
  //   }
  // };

  // const handleTouchStart = event => {
  //   event.stopPropagation();
  //   if (props.onTouchStart) {
  //     props.onTouchStart(event);
  //   }
  // };

  return (
    <ButtonTag
      className={classNames(
        "Button",
        inactive && "Button--inactive",
        theme && `Button--${theme}`,
        width && `Button--${width}`,
        size && `Button--${size}`,
        icon && iconPosition && `Button--icon-${iconPosition}`,
        bordered && `Button--bordered`,
        hideTextOnTablet && "Button--md-text-hidden",
        hideTextOnMobile && "Button--xs-text-hidden",
        className,
      )}
      to={to}
      // type={to ? undefined : "button"}
      // onClick={handleClick}
      // onTouchStart={handleTouchStart}
      {...accessibilityProps}
      {...props}
    >
      <span className="Button__text">{children}</span>
      {icon ? (
        <span className="Button__icon" style={{ height: `${icon.props.height}px` }}>
          {icon}
        </span>
      ) : null}
    </ButtonTag>
  );
};
