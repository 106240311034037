import { FONTS_CACHE_AGE } from "../constants";
import Cookies from "./cookies";

/**
 * Checks if the page was already loaded and fonts were cached
 * @param {String} cookies
 * @return {Boolean}
 */
export function checkFontsCached(cookies) {
  return Cookies.get("fontsLoaded", cookies);
}

/**
 * Remembers in cookie that fonts was loaded
 */
export function setFontsLoaded() {
  const consent =
    typeof window !== "undefined"
      ? JSON.parse(window.localStorage.getItem("__unic_consent_mode")) || {}
      : {};

  if (consent && consent.security_storage && consent.security_storage !== "denied") {
    Cookies.set("fontsLoaded", true, { path: "/", expires: FONTS_CACHE_AGE });
  }
}
