/* eslint-disable global-require */
import React from "react";
import { I18nProvider, withI18n } from "@lingui/react";
import { Helmet } from "react-helmet";
import LazyHydrate from "react-lazy-hydration";
import { DEFAULT_LANG } from "../../constants";
import { availableLangs } from "../../functions/urlLanguage";
import getTranslationsPaths from "../../functions/i18n/getTranslationsPaths";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import Header from "../Header";
import Footer from "../Footer";
import { Alert } from "../Alert";
import PageLoadingProvider from "../PageLoadingProvider";
import * as types from "../../stores/types";
import "./Root.css";
import usePreviousPageSave from "../../functions/react/usePreviousPageSave";

// TODO: migrate to lingui 3.x
// TODO: check with server side
const CATALOGS = getTranslationsPaths(availableLangs);

/**
 * Page title
 * In element to reach `i18n` object
 * @param {Object} $
 * @param {String} $.title - page title
 */
const PageTitle = withI18n()(({ i18n, title }) => (
  <Helmet>
    <title>{i18n._(title)}</title>
  </Helmet>
));

/**
 * Page title
 * In element to reach `i18n` object
 * @param {Object} $
 * @param {String} $.description - page title
 */
const PageDescription = withI18n()(({ i18n, description }) => (
  <Helmet>
    <meta name="description" content={i18n._(description)} />
  </Helmet>
));

/**
 * Root component with Header, Footer and translator
 * @param {Object} $
 * @param {String} $.language - language code to force set
 * @param {String} $.title - page title
 * @param {String} $.searchInHeader - CSS class of the element, after which Search will appear in Header
 * @param {Array[Object]} $.destinations - destinations for footer
 * @param {Array[React::Element]} $.children - page contents
 * @param {Boolean} $.stickyHeader - if Header stick to the screen
 * @param {Boolean} $.hideAppMarkets - hide AppStore and PlayMarket buttons in the footer
 * @param {Boolean} $.cookieFooter - insert `CookieFooter` component into DOM (cookies policy will be shown to users)
 */
function Root({
  language,
  title,
  description,
  children,
  stickyHeader,
  searchInHeader,
  helpInHeader,
  destinations = [],
  minimalHeader,
  minimalFooter,
  hideAppMarkets,
  header = true,
  hideHeader,
  savePageInHistory = true,
  footer = true,
  isMainPage = false,
}) {
  usePreviousPageSave(savePageInHistory);
  const lang = useCurrentLanguage();
  const locale = language || lang;
  // eslint-disable-next-line no-prototype-builtins
  const actualLanguage = CATALOGS.hasOwnProperty(locale) ? locale : DEFAULT_LANG;

  return (
    <I18nProvider catalogs={CATALOGS} language={actualLanguage}>
      <PageLoadingProvider>
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            href={require("../../favicons/32x32.png")}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={require("../../favicons/180x180.png")}
            sizes="180x180"
          />
          <link
            rel="icon"
            type="image/png"
            href={require("../../favicons/192x192.png")}
            sizes="192x192"
          />
          <link
            rel="icon"
            type="image/png"
            href={require("../../favicons/270x270.png")}
            sizes="270x270"
          />
        </Helmet>
        {title ? <PageTitle title={title} /> : null}
        {description ? <PageDescription description={description} /> : null}
        {header && !hideHeader ? (
          <Header
            {...(language ? { language } : {})}
            sticky={stickyHeader}
            showSearch={searchInHeader}
            showHelp={helpInHeader}
            minimal={minimalHeader}
            isMainPage={isMainPage}
          />
        ) : null}
        {children}
        {footer ? (
          <LazyHydrate whenVisible>
            <Footer
              lang={locale}
              destinations={destinations}
              minimal={minimalFooter}
              hideAppMarkets={hideAppMarkets}
            />
          </LazyHydrate>
        ) : null}
        <Alert />
      </PageLoadingProvider>
    </I18nProvider>
  );
}

Root.getInitialProps = async ({ store }) => {
  store.dispatch({ type: types.FETCH_LANGUAGES });
};

export default Root;
