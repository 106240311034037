/* eslint-disable import/no-cycle */
import React, { useMemo } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { useSelector } from "react-redux";
import { Trans } from "@lingui/macro";
import uuid from "react-uuid";
import { DEFAULT_LANG } from "../../constants";
import classNames from "../../functions/classNames";
import reverseUrl from "../../functions/reverseUrl";
import getDomainZone from "../../functions/url/getDomainZone";
import { allPaymentGates } from "../../functions/payments";
import NoSSR, { isSSR } from "../NoSSR";
import Image from "../Image";
import Accordion from "../Accordion";
import PaymentGates from "../PaymentGates";
import Apps from "./Apps";
import SocialIcons from "./SocialIcons";
import "./Footer.css";

/**
 * Importing icons and images
 */
import svgLogo from "./assets/logo.svg";

const FooterNavItem = ({ link, gap, title, text, ...linkProps }) => {
  return (
    <li
      className={classNames(
        "Footer__nav__item",
        gap && "Footer__nav__item--gap",
        title && "Footer__nav__item--title",
      )}
    >
      {!link ? (
        text
      ) : (
        <a href={link} className="Footer__nav__link" {...linkProps}>
          {text}
        </a>
      )}
    </li>
  );
};

/**
 * Footer for the page
 * @param {Object} $
 * @param {String} $.lang - current language code
 * @param {Array[Object]} $.destinations - array with destination descriptions (origin - `overview.topCities`)
 * @param {Boolean} $.minimal - hide everything from Footer except bottom panel with logo
 * @param {Boolean} $.hideAppMarkets - hide AppStore and PlayMarket buttons in the footer
 */
const Footer = ({ lang = DEFAULT_LANG, destinations = [], minimal, hideAppMarkets }) => {
  const isMobile = useMediaQuery("(max-width: 767px)") && !isSSR;
  const currencyCode = useSelector(state => state.currencies.currentCurrency);
  const DNSZone = getDomainZone(lang);
  const links1 = useMemo(() => [
    { title: true, text: <Trans>Destinations</Trans> },
    ...destinations.slice(0, 6).map(destination => ({
      link: reverseUrl("city", {
        lang,
        citySlug: destination.slug,
        cityId: destination.id,
      }),
      text: destination.name,
    })),
    { link: reverseUrl("destinations", { lang }), text: <Trans>All destinations</Trans> },
  ]);
  const links2 = useMemo(() => [
    { title: true, text: <Trans>Company</Trans> },
    { link: `https://wegotrip.${DNSZone}/about/`, text: <Trans>About</Trans> },
    {
      link: `https://wegotrip.${DNSZone}/${lang === "ru" ? "vacancies" : "careers"}/`,
      text: <Trans>Careers</Trans>,
    },
    lang !== "ru" && {
      link: `https://wegotrip.${DNSZone}/blog/`,
      text: <Trans>WeGoTrip Blog</Trans>,
    },
    {
      link: `https://wegotrip.${DNSZone}/support/`,
      text: <Trans>Help Center</Trans>,
    },
    {
      link: `https://wegotrip.${DNSZone}/terms/`,
      text: <Trans>Terms and Conditions</Trans>,
      target: "_blank",
    },
    {
      link: `https://wegotrip.${DNSZone}/privacy-policy/`,
      text: <Trans>Privacy Policy</Trans>,
      target: "_blank",
    },
    // { link: "https://wegotrip.${DNSZone}/contact-us/", text: <Trans>Contacts</Trans> },
  ]);
  const links3 = useMemo(() => [
    { title: true, text: <Trans>Partnership</Trans> },
    {
      link: `https://wegotrip.${DNSZone}/suppliers/`,
      text: <Trans>Become a supplier</Trans>,
    },
    {
      link: `https://wegotrip.${DNSZone}/partners/`,
      text: <Trans>Affiliate program</Trans>,
    },
    {
      link: `https://wegotrip.${DNSZone}/museums/`,
      text: <Trans>For museums and attractions</Trans>,
    },
    lang !== "ru" && {
      link: `https://wegotrip.${DNSZone}/business_solution/`,
      text: "Business solution",
    },
  ]);
  const links35 = [
    { title: true, text: <Trans>Materials</Trans> },
    { link: `https://wegotrip.${DNSZone}/ru/blog/`, text: <Trans>Articles</Trans> },
  ];
  const links4 = [];

  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={classNames(
        "Footer",
        minimal && "Footer--minimal",
        hideAppMarkets && "Footer--no-markets",
      )}
    >
      <div className="Wrapper">
        {minimal ? null : (
          <div className="row Footer__top">
            <div className="col-md-2">
              <NoSSR>
                {isMobile ? (
                  <Accordion title={links1.find(item => item.title).text}>
                    <ul className="Footer__nav">
                      {links1
                        .filter(item => !item.title)
                        .map(item => {
                          return <FooterNavItem key={uuid()} {...item} />;
                        })}
                    </ul>
                  </Accordion>
                ) : (
                  <ul className="Footer__nav">
                    {links1.map(item => {
                      return <FooterNavItem key={uuid()} {...item} />;
                    })}
                  </ul>
                )}
              </NoSSR>
            </div>
            <div className="col-md-2">
              <NoSSR>
                {isMobile ? (
                  <Accordion title={links2.find(item => item.title).text}>
                    <ul className="Footer__nav">
                      {links2
                        .filter(item => !item.title)
                        .map(item => {
                          return <FooterNavItem key={uuid()} {...item} />;
                        })}
                    </ul>
                  </Accordion>
                ) : (
                  <ul className="Footer__nav">
                    {links2.map(item => {
                      return <FooterNavItem key={uuid()} {...item} />;
                    })}
                  </ul>
                )}
              </NoSSR>
            </div>
            <div className="col-md-4">
              <NoSSR>
                {isMobile ? (
                  <>
                    <Accordion title={links3.find(item => item.title).text}>
                      <ul className="Footer__nav">
                        {links3
                          .filter(item => !item.title)
                          .map(item => {
                            return <FooterNavItem key={uuid()} {...item} />;
                          })}
                      </ul>
                    </Accordion>
                    {lang === "ru" ? (
                      <Accordion title={links35.find(item => item.title).text}>
                        <ul className="Footer__nav">
                          {links35
                            .filter(item => !item.title)
                            .map(item => {
                              return <FooterNavItem key={uuid()} {...item} />;
                            })}
                        </ul>
                      </Accordion>
                    ) : null}
                  </>
                ) : (
                  <ul className="Footer__nav">
                    {links3.map(item => {
                      return <FooterNavItem key={uuid()} {...item} />;
                    })}
                    {lang === "ru"
                      ? [{ gap: true, text: "" }, ...links35].map(item => {
                          return <FooterNavItem key={uuid()} {...item} />;
                        })
                      : null}
                  </ul>
                )}
              </NoSSR>
              <ul className="Footer__nav Footer__nav--one">
                {links4.map(item => {
                  return <FooterNavItem key={uuid()} {...item} />;
                })}
              </ul>
            </div>
            <div className="col-md-4">
              <div className="Footer__label Footer__label--markets">
                <Trans>Mobile App</Trans>
              </div>
              <Apps />
              <div className="Footer__label">
                <Trans>Payment methods</Trans>
              </div>
              <PaymentGates theme="white" gates={allPaymentGates(currencyCode)} />
            </div>
          </div>
        )}
        <div className="row Footer__bottom">
          <div className="col-md-4">
            <Image
              staticImage
              src={svgLogo}
              loading="lazy"
              alt={lang === "ru" ? "Логотип WeGoTrip" : "WeGoTrip Logo"}
            />
          </div>
          <div className="col-md-4 Footer__socials">
            <SocialIcons lang={lang} />
          </div>
          <div className="col-md-4 Footer__copy d-xs-none">© {currentYear} WeGoTrip</div>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
