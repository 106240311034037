import { takeEvery, put, call, select } from "redux-saga/effects";
import * as types from "../types";
import { Api } from "../../functions/fetchFromApi";
import toQueryString from "../../functions/toQueryString";
import { formatDate } from "../../functions/formatDate";

/**
 * Fetches list of products by given query params or by ids
 * @param {Object} action
 * @param {String} action.lang - product translations language code
 * @param {String} action.countryId - query param, id of the country tour takes part
 * @param {String} action.cityId - query param, id of the city tour takes part
 * @param {String} action.categoryId - query param, id of the category tour related to
 * @param {String} action.attractionId - query param, id of the attraction tour includes
 * @param {Array[Number]} action.ids - list of product ids to fetch (provided query params will be ignored)
 * @param {Array[Object]} action.products - if specified will be added to fetched products (no fetching will happen)
 * @param {Boolean} action.append - append data to the already fetched
 */
function* productsFetch(action) {
  let { page = 1 } = action;
  const {
    author,
    provider,
    countryId,
    cityId,
    categoryId,
    attractionId,
    append,
    lang,
    perPage,
    ratingGte,
    audio,
    durationGte,
    durationLte,
    priceGte,
    priceLte,
    availableFrom,
    availableTill,
    ids = [],
    preorder = true,
    products: externalProducts = [],
    separateTo,
    storeBoth,
    order,
  } = action;
  if (!page) {
    page = 1;
  }

  let products = [];
  let queryData = {};
  const loadedProducts = yield select(({ products: productsStore }) => {
    const separatedProducts = productsStore[separateTo]?.products;
    return separatedProducts?.length ? separatedProducts : productsStore.products;
  });

  const currency = yield select(state => state.currencies.currentCurrency);
  const formattedAvailableFrom = availableFrom ? formatDate(availableFrom) : null;
  const formattedAvailableTill = availableTill ? formatDate(availableTill) : null;
  try {
    if (!externalProducts.length) {
      const queryParams = {
        author,
        provider,
        country: countryId,
        city: cityId,
        category: Array.isArray(categoryId) ? categoryId.join(",") : categoryId,
        attraction: attractionId,
        audio,
        lang: Array.isArray(lang) ? lang.join(",") : lang,
        currency,
        product_id: ids.join(","),
        per_page: perPage,
        page,
        preorder,
        rating_gte: ratingGte,
        duration_gte: durationGte,
        duration_lte: durationLte,
        price_gte: priceGte,
        price_lte: priceLte,
        available_from: formattedAvailableFrom,
        available_till: formattedAvailableTill,
        order,
      };

      let queryString = toQueryString(queryParams);

      queryString += `&expand=description,images`;

      const apiEndpoint = !ids.length ? "/api/v2/products/popular/" : "/api/v2/products/";
      const { data } = yield call(Api.get, `${apiEndpoint}?${queryString}`, {
        lang,
      });

      products = data.results;
      queryData = data;
    }

    yield put({
      type: types.FETCH_PRODUCTS_SUCCESS,
      separateTo,
      storeBoth,
      data: append
        ? { ...queryData, results: [...loadedProducts, ...products, ...externalProducts] }
        : { ...queryData, results: [...products, ...externalProducts] },
    });
  } catch (error) {
    yield put({ type: types.FETCH_PRODUCTS_FAILURE, error });
  }
}

function* fetchMostRecommendedProducts(action) {
  const { city, category, attraction, lang } = action.payload;

  try {
    const queryParams = {
      city,
      lang,
      perPage: 10,
      expand: "description,images",
      preorder: true,
      ...(category && { category }),
      ...(attraction && { attraction }),
    };

    const queryString = toQueryString(queryParams);
    const { data } = yield call(Api.get, `/api/v2/products/popular/?${queryString}`);

    yield put({
      type: types.FETCH_MOST_RECOMMENDED_SUCCESS,
      data: data.results,
    });
  } catch (error) {
    yield put({ type: types.FETCH_MOST_RECOMMENDED_FAILURE, error });
  }
}

function* fetchSelectedProduct({ id, lang }) {
  const currency = yield select(state => state.currencies.currentCurrency);

  try {
    const { data: product } = yield call(
      Api.get,
      `/api/v3/products/${id}/?${toQueryString({ currency, lang, preorder: true })}`,
      { lang },
    );

    yield put({
      type: types.FETCH_SELECTED_PRODUCT_SUCCESS,
      product,
    });

    if (product.tickets.length) {
      // TODO: think about spreading FETCH_DAYS across components
      yield put({ type: types.FETCH_DAYS, productId: id, days: 30 });
    }
  } catch (error) {
    yield put({ type: types.FETCH_SELECTED_PRODUCT_FAILURE, error });
  }
}

function* getSimilarProducts(action) {
  const { product, lang, currency } = action.payload;
  try {
    const response = yield call(
      Api.get,
      `/api/v2/products/${product.id}/similar/?${toQueryString({ lang, currency })}`,
      {
        lang,
      },
    );
    yield put({
      type: types.GET_SIMILAR_PRODUCTS_SUCCESS,
      payload: response.results || [],
    });
  } catch (error) {
    yield put({
      type: types.GET_SIMILAR_PRODUCTS_FAILURE,
      payload: error,
    });
  }
}

function* getUpSaleProducts(action) {
  const { product, lang, currency } = action.payload;
  try {
    const response = yield call(
      Api.get,
      `/api/v2/products/${product.id}/related/?${toQueryString({ lang, currency })}`,
      {
        lang,
      },
    );
    yield put({
      type: types.GET_UPSALE_PRODUCTS_SUCCESS,
      payload: response.results || [],
    });
  } catch (error) {
    yield put({
      type: types.GET_UPSALE_PRODUCTS_FAILURE,
      payload: error,
    });
  }
}

export default function* watch() {
  yield takeEvery(types.FETCH_PRODUCTS, productsFetch);
  yield takeEvery(types.FETCH_SELECTED_PRODUCT, fetchSelectedProduct);
  yield takeEvery(types.FETCH_MOST_RECOMMENDED, fetchMostRecommendedProducts);
  yield takeEvery(types.FETCH_SIMILAR_PRODUCTS, getSimilarProducts);
  yield takeEvery(types.FETCH_UPSALE_PRODUCTS, getUpSaleProducts);
}
